<template>
  <div class="mt-2 row no-gutters mx-0">
      <div class="col-1">
        <nav class="navbar-light text-left
                    text-smallest font-weight-bold
                    side-nav mr-2 bg-white min-vh-100">
          <ul class="nav flex-column h-100p text-left">
            <li class="nav-item">
              <router-link :to="{name:'CrewPersonal'}" tag="a"
                           active-class="active"
                           class="nav-link text-dark">
                Personal Information
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name:'CrewAddress'}" tag="a"
                           active-class="active"
                           class="nav-link text-dark">
                Address & <br />Contact Details
              </router-link>

            </li>
            <li class="nav-item">
              <router-link :to="{name:'CrewEducation'}" tag="a"
                           active-class="active"
                           class="nav-link text-dark">
                Education
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name:'CrewFamily'}" tag="a"
                           active-class="active"
                           class="nav-link text-dark">
                Family
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-11">
        <router-view />
      </div>
  </div>

</template>

<script>
export default {
  name: "CrewProfile.vue"
}
</script>

<style scoped>
.nav-link.active,.nav-link:hover {
  background-color: #e87c87;
  color: white !important;
}
</style>
